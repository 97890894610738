@import '../_variables';

.nav {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0px 8px;
}

/** Progress Bar */
$progress-height: 5px;
$duration: 3s;

#top {
  .guide {
    textarea {
      min-height: 100px;
    }
  
    textarea,
    input[type='text'] {
      border-radius: 4px;
      margin: 20px 0px;
    }
  
    .error {
      textarea,
      input[type='text'] {
        border: 1px solid red;
      }
    }
  }
}

.wizard {
  .bulk {
    min-height: 100px;
  }
}

.rsw-wrapper {
  min-height: 400px !important;
}

@keyframes stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes rainbow {
  0% {
    background-color: var(--red);
  }
  20% {
    background-color: var(--orange);
  }
  40% {
    background-color: var(--yellow);
  }
  60% {
    background-color: var(--green);
  }
  80% {
    background-color: var(--blue);
  }
  100% {
    background-color: var(--indigo);
  }
}

@keyframes grow {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.input-domains {
  display: grid;
  grid-template-columns: 8fr 2fr;
  gap: 20px;

  .toggle-scan {
    margin: 20px 0 !important;
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr;
    gap: 10px;

    .toggle-scan {
      margin: 0 0 10px 0 !important;
    }
  }
}
