@import '_variables';

html #top #wrap_all #main {
  margin-left: auto;
}

html #top #wrap_all #header {
  width: 100%;
  // overflow: hidden;
}
html .header .header-content {
  height: auto;
  margin: 0 auto;
  max-width: 1150px;
}
html .header .header-content .header-content-inner {
  position: relative;
  height: auto;
}
html #top #wrap_all {
  display: block;
  height: 100%;
}

html.header-is-scrolled .header .header-inner {
  .header-logo {
    @media (max-width: $breakpoint-tablet) {
      opacity: 100;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin: 0 auto;
  justify-content: space-between;

  .social {
    padding: 20px;
    cursor: pointer;
  }
}

html .header .header-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  padding: 20px 0px 20px;

  @media (min-width: $breakpoint-tablet) {
    padding: 20px 0px 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 75% 25%;
  }
}

.header .menu-button {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 14px 30px;

  @media (max-width: $breakpoint-tablet) {
    display: flex;
  }
}

html.header-is-scrolled .header .header-inner {
  height: auto;
}

.menu-main {
  display: flex;
  flex-direction: row;
}

.hamburger-content {
  display: flex;
  flex-direction: row;
}

.menu-item {
  color: #fff;
}

html.menu-collapsed #header .header-inner .header-logo {
  @media (min-width: $breakpoint-tablet) {
    width: auto;
  }
}

.header-logo {
  padding: 0;

  @media (max-width: $breakpoint-tablet) {
    padding: 10px 0px 10px 40px;
  }
}

html.no-toolkit-selected
  .hamburger-content
  .hamburger-content-inner
  .main-menu-content
  > ul.menu-main::before {
  display: none;
}

.main-menu-content {
  display: flex;
  flex-direction: row;
  .menu-item {
    margin: 4px 10px;
    line-height: 20px;

    @media (max-width: $breakpoint-desktop) {
      margin: 4px 6px;
    }
  
    .active {
      font-weight: bold;
    }
  }
}

html.menu-collapsed #header {
  @media only screen and (min-width: 768px) {
    width: auto !important;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes leaves {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0.6);
  }
}

.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #1bb28c;
}
