#top {
  .servers,
  .domains {
    display: grid;
    grid-template-columns: 13fr 1fr;
    gap: 20px;
    margin: 20px 0;

    .remove {
      cursor: pointer;
    }

    input[type='text'] {
      margin: 0;
    }
  }

  .results-input {
    margin: 20px 0;

    .results-row {
      display: grid;
      grid-template-columns: 1fr 2fr 6fr 1fr;
      gap: 20px;
      
      &.h {
        font-weight: bold;
      }

      input[type="text"] {
        border: 1px solid #000;
        }
    }
  }

  .servers__select {
    margin-top: 10px;

    select {
      cursor: pointer;
    }
  }

  .strict__select {
    margin-top: 10px;
    cursor: pointer;
  }
}

.hs-button {
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 12px;
  position: relative;
  text-align: center;
  transition: all 0.15s linear;
  background-color: #ff7a59;
  border-color: #ff7a59;
  color: #fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 12px 24px;

  &.hs-blue {
    background-color: rgb(46, 142, 255);
  }
}
