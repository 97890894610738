@import '../variables';

#top select.lang-select {
  margin: 4px;
  option {
    color: #000000;
  }
}
.translate-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #446193;

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    justify-content: center;
  }

  .flags {
    img {
      vertical-align: middle;
      padding: 2px 5px;
    }
  }

  .select {
    width: 250px;
  }
}
