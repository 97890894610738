@import './pages/_variables';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-menu-bottom {
  .dmarc {
    height: 12px;
  }
}

.page-wrapper {
  display: block;
  width: auto;
  height: auto;
  max-width: $breakpoint-desktop;
  margin: 0 auto;
  padding-bottom: 40px;
  clear: both;
  background-color: #fff;

  @media (max-width: $breakpoint-tablet) {
    max-width: $breakpoint-tablet;
    margin-top: 100px;
  }
}

.avia-section-huge .content {
  padding-top: 60px;
  padding-bottom: 60px;
}

#top {
  .wizard {
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 20px;
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }

    }

    .button {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-top: 20px;
    }

    .step {
      h2 {
        font-weight: bold;
      }
    }
  }
}

.btn-blue {
  background-color: $gca-light-blue !important;
  color: $gca-white !important;
}

.btn-green {
  background-color: $gca-green !important;
  color: $gca-white !important;
  border-color: 1px solid $gca-white !important;
  box-shadow: none !important;
}
