.reportgen-inputs {
  display: flex;

  .group {
    margin-right: 20px;
  }

  input {
    cursor: pointer;
  }

  label {
    padding-left: 5px;
    cursor: pointer;
  }
}


.results-input {
  margin: 20px 0;

  .results-row {
    display: grid;
    grid-template-columns: 1fr 2fr 6fr 1fr;
    gap: 20px;
    
    &.h {
      font-weight: bold;
    }

    input[type="text"] {
      border: 1px solid #000;
      }
  }
}
.seconds-converter {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 0.2fr 1fr 8fr;
  align-items: center;

  input[type="text"] {
    margin: 0;
  }
}