@import '../../variables.scss';
#top {
  .results {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    text-align: center;
    gap: 20px;
    @media (max-width: 767px) {
      gap: 0px;
      margin-bottom: 50px;
    }
    align-items: center;

    .res {
      border-radius: 4px;
      padding: 10px;
      transition: box-shadow 0.3s ease-in-out;
      position: relative;
      overflow: clip;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 767px) {
        padding: 5px;
        margin-bottom: -50px;
      }

      .header-container {
        display: flex;
        justify-content: center;
        text-align: center;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        height: 50%;
        right: -10px;
        top: 25%;
        border-right: 1px solid rgb(169, 169, 169);
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .icon {
      color: red;
      font-size: 30px;
      margin-bottom: 10px;
      &.checked {
        color: green;
      }
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .header {
      background-color: inherit;
      margin: auto;
      font-size: 32px;
      font-weight: bold;

    }

    }

    .hs-button {
      color: #fff;
    }

    .details {
      font-size: 12px;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
    }
  }

  .copy-input {
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap: 20px;
    margin: 20px 0;

    input[type='text'] {
      margin: 0;
    }

    .copied {
      background-color: green;
      color: #fff;
      transition: all 0.3s ease-in;
    }
  }

  .advanced-section {
    h5 {
      color: $gca-blue;
      user-select: none;
      cursor: pointer;
    }
  }

  .dns-record {
    display: grid;
    grid-template-columns: 1fr 15fr;
    align-items: flex-start;
    gap: 10px;
  }

  .dns-record.dual {
    grid-template-columns: repeat(2, 1fr);
  }

  .dns-record label {
    text-align: left;
    color: $gca-blue;
    white-space: nowrap;
    font-size: medium;
    min-width: 120px;
    margin-top: 24px;
  }

  .dns-record input {
    width: 100%;
  }

  .dns-record textarea {
    width: 70%;
    margin-right: 20px;
  }

  .dns-record.dual .dns-record {
    grid-template-columns: max-content 1fr;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
.cred {
  color: red;
}
